import { createTheme } from '@mantine/core';

// ref: https://mantine.dev/theming/theme-object/
export const theme = createTheme({
  /** Put your mantine theme override here */

  // ref on using system vs web fonts: https://iloveseo.com/seo/system-fonts-vs-web-fonts-why-does-the-difference-matter/
  // https://systemfontstack.com/
  
  // fontFamily: "Roboto, sans-serif",
  // fontFamilyMonospace: 'Monaco, Courier, monospace',
  // headings: { fontFamily: 'Montserrat, sans-serif' },

  primaryColor:"violet",
  primaryShade: 6,

});
