import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css'; //if using mantine date picker features
import 'mantine-react-table/styles.css'; //import MRT styles
import '@/global.css';
import { MantineProvider, Stack } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { Router } from './Router';
import { theme } from './theme';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query'
import { Footer } from './components/Footer';

const queryClient = new QueryClient();

export default function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      <QueryClientProvider client={queryClient}>
        <Stack justify='space-between'>
          <Router />
          <Footer />
        </Stack>
      </QueryClientProvider>
    </MantineProvider>
  );
}
