import { LoadingOverlay } from "@mantine/core";

type OverlayLoaderProps = {
    
};

export function OverlayLoader(props:OverlayLoaderProps){

    return (
        <LoadingOverlay 
            visible={true}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 3 }} 
            loaderProps={{ color: 'violet', type: 'oval', size: "xl" }}
      />
    );
}