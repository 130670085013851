import { OverlayLoader } from '@/components/OverlayLoader';
import { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// import HomePage from '@/pages/Home/Home';
// import  MobileDesktopDiscrepancy  from '@/pages/MobileDesktopDiscrepancy/MobileDesktopDiscrepancy';
// import  Demo  from "@/pages/Demo/Demo"
// import  Demo2  from '@/pages/Demo2/Demo2';

// Use React.lazy for code splitting
const HomePage = lazy(() => import('@/pages/Home/Home'));
const MobileDesktopDiscrepancy = lazy(() => import('@/pages/MobileDesktopDiscrepancy/MobileDesktopDiscrepancy'));
const SiteSimilaritySearch = lazy(() => import('@/pages/SiteSimilaritySearch/SiteSimilaritySearch'));
const ImageOptimization = lazy(() => import('@/pages/ImageOptimization/ImageOptimization'));
const Demo = lazy(() => import('@/pages/Demo/Demo'));
const Demo2 = lazy(() => import('@/pages/Demo2/Demo2'));

export const ROUTES = [
  {
    path: '/',
    name:"Home",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path:"/mobd",
    name:"Mobile Discrepancy",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <MobileDesktopDiscrepancy />
      </Suspense>
    ),
  },
  {
    path:"/site-similarity-search",
    name:"Site Similarity Search",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <SiteSimilaritySearch />
      </Suspense>
    ),
  },
  {
    path:"/image-optimization",
    name:"Image Optimization",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <ImageOptimization />
      </Suspense>
    ),
  },
  {
    path:"/demo",
    name:"Demo",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <Demo />
      </Suspense>
    ),
  },
  {
    path:"/demo2",
    name:"Demo 2",
    element: (
      <Suspense fallback={<OverlayLoader/>}>
        <Demo2 />
      </Suspense>
    ),
  },
];

const router = createBrowserRouter(ROUTES);

export function Router() {
  return <RouterProvider router={router} />;
}
