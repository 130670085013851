import { Text, Image, Stack, Accordion, Spoiler, Space, Title, Group, Card, Code, Anchor } from "@mantine/core";
import exampleTable from "@/assets/example-table-2.png";
import { IconArrowRightCircle, IconCircleNumber1, IconCircleNumber2, IconNumber1, IconPlus } from "@tabler/icons-react";

type FooterProps = {

};

export function Footer(props:FooterProps){
    return (
        <Group
            // align="center"
            justify="center"
        >
        <Anchor             
            href="https://aisoft.dev/" 
            target="_blank"
            underline="never"                    
            >
            <Text size="sm" ta="center" c="dimmed">
                &copy; {new Date().getFullYear()} AISOFT DEVELOPMENT LLC. All rights reserved.
            </Text>
        </Anchor>
        </Group>
    );
}